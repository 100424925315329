import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Home() {

    const [data, setData] = useState("no");

    const navigation = useNavigate();

    function navigateFun(){
        console.log(data);
        if(data == "yes"){
            navigation("/contact");
        }else{
            navigation("/about");

        }
    }



    return (
        <div className='con'>
            <h2>Home</h2>
            
            <button className='btn btn-primary' onClick={navigateFun}>Click Here</button>
        </div>
    )
}

export default Home